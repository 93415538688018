<template>
  <div>
   <base-header class="pb-1">
       <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Relatório de Previsão de Recebimento</h6>
        </div>
      </div>
    </base-header>

   <div class="container-fluid mt--6">
    <form>
        <card class="d-print-none">
            <h3 slot="header" class="mb-0">Relatório de Previsão de Recebimento</h3>
            <div class="form-row mt-3">
                <div class="col-md-6">
                    <base-input addon-left-icon="ni ni-calendar-grid-58" label="Data Inicio">
                        <flat-picker slot-scope="{focus, blur}"
                                    @on-open="focus"
                                    @on-close="blur"
                                    :config="{allowInput: true, dateFormat: 'd/m/Y', altFormat: 'M j, Y'}"
                                    class="form-control datepicker"
                                    placeholder="Data Inicial"
                                    v-model="reportFilter.dateBegin">
                        </flat-picker>
                    </base-input>
                </div>   
                <div class="col-md-6">
                    <base-input addon-left-icon="ni ni-calendar-grid-58" label="Data Final">
                        <flat-picker slot-scope="{focus, blur}"
                                    @on-open="focus"
                                    @on-close="blur"
                                    :config="{allowInput: true, dateFormat: 'd/m/Y', altFormat: 'M j, Y'}"
                                    class="form-control datepicker"
                                    placeholder="Data Final"
                                    v-model="reportFilter.dateEnd">
                        </flat-picker>
                    </base-input>
                </div>
            </div>    
            <div class="col-md-12 text-right mb-3">
                <base-button type="secundary" native-type="button" @click="printReport"><i class="fas fa-print mr-1"></i>Imprimir</base-button>
                <base-button type="secundary" native-type="button" @click="exportReport"><i class="far fa-file-excel mr-1"></i>Exportar</base-button>
                <base-button type="primary" native-type="button" @click="filterReport"><i class="fas fa-funnel-dollar mr-1"></i> Filtrar</base-button>
            </div>
        </card>

        <div class="col-md-12">
                <card>
                <h3 slot="header">Previsão de Recebimento</h3>

                    <template>
                        <tabs class="my-tabs">
                            <template slot="vue">
                                Recebido  <strong>{{ totalValueReceived }} ({{ totalReceived }})</strong>
                            </template>
                            <template slot="react">
                                A Receber <strong>{{ totalValueReceivable }} ({{ totalReceivable }})</strong>
                            </template>
                            <template slot="teste">
                                Inadimplentes <strong>{{ totalValueDefaulters }} ({{ totalDefaulters }})</strong>
                            </template>
                            <tab title-slot="vue">

                                <table class="table table-responsive font-tabela-peq">
                                    <thead class="thead">
                                        <tr>
                                            <th scope="col">Assinatura</th>
                                            <th scope="col">Fatura</th>
                                            <th scope="col">Titular</th>
                                            <th scope="col">Forma de Pag Assinatura</th>
                                            <th scope="col">Dt. Pagamento</th>
                                            <th scope="col">Plano</th>
                                            <th scope="col">Preço</th>
                                            <th scope="col">Tipo Fatura</th>
                                            <th scope="col">Tipo Pag Realizado</th>
                                            <th scope="col">Telefone</th>
                                            <th scope="col">Bairro</th>
                                            <th scope="col">CEP</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="report in listaDetailsReceived" :key="report.invoiceId">
                                            <td>{{report.idSubscription}}</td>
                                            <td>{{report.idInvoice}}</td>
                                            <td><a href="javascript:void(0);" @click="openSubscription(report.idSubscription)">{{report.holderName}}</a></td>
                                            <td>
                                                <span v-if="report.subsPaymentType == 'CREDIT_CARD'">Cartão/Cred</span>
                                                <span v-if="report.subsPaymentType == 'DEBIT_CARD'">Cartão/Deb</span>
                                                <span v-if="report.subsPaymentType == 'TICKET'">Boleto</span>
                                                <span v-if="report.subsPaymentType == 'TICKETS'">Boleto/Carnê</span>
                                                <span v-if="report.subsPaymentType == 'DEBIT_CARD_LOCAL'">Cartão/Deb (Maq)</span>
                                                <span v-if="report.subsPaymentType == 'CREDIT_CARD_LOCAL'">Cartão/Cred (Maq)</span>
                                                <span v-if="report.subsPaymentType == 'MONEY'">Dinheiro</span>
                                            </td>
                                            <td>{{report.paymentDate}}</td>
                                            <td>{{report.planName}}</td>
                                            <td>{{report.amountPaidFmt}}</td>
                                            <td>
                                                <span v-if="report.invoiceType == 'DEFAULT'">Mensalidade</span>
                                                <span v-if="report.invoiceType == 'EXTRA'">Avulso</span>
                                                <span v-if="report.invoiceType == 'AGREEMENT'">Acordo</span>
                                                <span v-if="report.invoiceType == 'DEPENDENT'">Dependente</span>
                                            </td>
                                            <td>
                                                <span v-if="report.paymentType == 'CREDIT_CARD'">Cartão/Cred</span>
                                                <span v-if="report.paymentType == 'DEBIT_CARD'">Cartão/Deb</span>
                                                <span v-if="report.paymentType == 'TICKET'">Boleto</span>
                                                <span v-if="report.paymentType == 'TICKETS'">Boleto/Carnê</span>
                                                <span v-if="report.paymentType == 'DEBIT_CARD_LOCAL'">Cartão/Deb (Maq)</span>
                                                <span v-if="report.paymentType == 'CREDIT_CARD_LOCAL'">Cartão/Cred (Maq)</span>
                                                <span v-if="report.paymentType == 'MONEY'">Dinheiro</span>
                                            </td>
                                            <td>{{report.cellphone}}</td>
                                            <td>{{report.neighborhood}}</td>
                                            <td>{{report.zipCode}}</td>
                                        </tr>
                                        <tr v-show="listaDetailsReceived.length == 0">
                                            <td colspan="8">
                                            <center> Nenhuma informação disponível no relatório para o período informado.</center>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </tab>
                            <tab title-slot="react">
                                
                                <table class="table table-responsive font-tabela-peq">
                                    <thead class="thead">
                                        <tr>
                                            <th scope="col">Assinatura</th>
                                            <th scope="col">Fatura</th>
                                            <th scope="col">Titular</th>
                                            <th scope="col">Forma de Pag Assinatura</th>
                                            <th scope="col">Dt. Vencimento</th>
                                            <th scope="col">Plano</th>
                                            <th scope="col">Preço</th>
                                            <th scope="col">Tipo Fatura</th>
                                            <th scope="col">Tipo Pag Realizado</th>
                                            <th scope="col">Telefone</th>
                                            <th scope="col">Bairro</th>
                                            <th scope="col">CEP</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="report in listaDetailsReceivable" :key="report.invoiceId">
                                            <td>{{report.idSubscription}}</td>
                                            <td>{{report.idInvoice}}</td>
                                            <td><a href="javascript:void(0);" @click="openSubscription(report.idSubscription)">{{report.holderName}}</a></td>
                                            <td>
                                                <span v-if="report.subsPaymentType == 'CREDIT_CARD'">Cartão/Cred</span>
                                                <span v-if="report.subsPaymentType == 'DEBIT_CARD'">Cartão/Deb</span>
                                                <span v-if="report.subsPaymentType == 'TICKET'">Boleto</span>
                                                <span v-if="report.subsPaymentType == 'TICKETS'">Boleto/Carnê</span>
                                                <span v-if="report.subsPaymentType == 'DEBIT_CARD_LOCAL'">Cartão/Deb (Maq)</span>
                                                <span v-if="report.subsPaymentType == 'CREDIT_CARD_LOCAL'">Cartão/Cred (Maq)</span>
                                                <span v-if="report.subsPaymentType == 'MONEY'">Dinheiro</span>
                                            </td>
                                            <td>{{report.paymentDate}}</td>
                                            <td>{{report.planName}}</td>
                                            <td>{{report.amountPaidFmt}}</td>
                                            <td>
                                                <span v-if="report.invoiceType == 'DEFAULT'">Mensalidade</span>
                                                <span v-if="report.invoiceType == 'EXTRA'">Avulso</span>
                                                <span v-if="report.invoiceType == 'AGREEMENT'">Acordo</span>
                                                <span v-if="report.invoiceType == 'DEPENDENT'">Dependente</span>
                                            </td>
                                            <td>
                                                <span v-if="report.paymentType == 'CREDIT_CARD'">Cartão/Cred</span>
                                                <span v-if="report.paymentType == 'DEBIT_CARD'">Cartão/Deb</span>
                                                <span v-if="report.paymentType == 'TICKET'">Boleto</span>
                                                <span v-if="report.paymentType == 'TICKETS'">Boleto/Carnê</span>
                                                <span v-if="report.paymentType == 'DEBIT_CARD_LOCAL'">Cartão/Deb (Maq)</span>
                                                <span v-if="report.paymentType == 'CREDIT_CARD_LOCAL'">Cartão/Cred (Maq)</span>
                                                <span v-if="report.paymentType == 'MONEY'">Dinheiro</span>
                                            </td>
                                            <td>{{report.cellphone}}</td>
                                            <td>{{report.neighborhood}}</td>
                                            <td>{{report.zipCode}}</td>
                                        </tr>
                                        <tr v-show="listaDetailsReceivable.length == 0">
                                            <td colspan="8">
                                            <center> Nenhuma informação disponível no relatório para o período informado.</center>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>                                

                            </tab>
                            <tab title-slot="teste">
                                
                                <table class="table table-responsive font-tabela-peq">
                                    <thead class="thead">
                                        <tr>
                                            <th scope="col">Assinatura</th>
                                            <th scope="col">Fatura</th>
                                            <th scope="col">Titular</th>
                                            <th scope="col">Forma de Pag Assinatura</th>
                                            <th scope="col">Dt. Vencimento</th>
                                            <th scope="col">Plano</th>
                                            <th scope="col">Preço</th>
                                            <th scope="col">Tipo Fatura</th>
                                            <th scope="col">Tipo Pag Realizado</th>
                                            <th scope="col">Telefone</th>
                                            <th scope="col">Bairro</th>
                                            <th scope="col">CEP</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="report in listaDetailsDefaulters" :key="report.invoiceId">
                                            <td>{{report.idSubscription}}</td>
                                            <td>{{report.idInvoice}}</td>
                                            <td><a href="javascript:void(0);" @click="openSubscription(report.idSubscription)">{{report.holderName}}</a></td>
                                            <td>
                                                <span v-if="report.subsPaymentType == 'CREDIT_CARD'">Cartão/Cred</span>
                                                <span v-if="report.subsPaymentType == 'DEBIT_CARD'">Cartão/Deb</span>
                                                <span v-if="report.subsPaymentType == 'TICKET'">Boleto</span>
                                                <span v-if="report.subsPaymentType == 'TICKETS'">Boleto/Carnê</span>
                                                <span v-if="report.subsPaymentType == 'DEBIT_CARD_LOCAL'">Cartão/Deb (Maq)</span>
                                                <span v-if="report.subsPaymentType == 'CREDIT_CARD_LOCAL'">Cartão/Cred (Maq)</span>
                                                <span v-if="report.subsPaymentType == 'MONEY'">Dinheiro</span>
                                            </td>
                                            <td>{{report.paymentDate}}</td>
                                            <td>{{report.planName}}</td>
                                            <td>{{report.amountPaidFmt}}</td>
                                            <td>
                                                <span v-if="report.invoiceType == 'DEFAULT'">Mensalidade</span>
                                                <span v-if="report.invoiceType == 'EXTRA'">Avulso</span>
                                                <span v-if="report.invoiceType == 'AGREEMENT'">Acordo</span>
                                                <span v-if="report.invoiceType == 'DEPENDENT'">Dependente</span>
                                            </td>
                                            <td>
                                                <span v-if="report.paymentType == 'CREDIT_CARD'">Cartão/Cred</span>
                                                <span v-if="report.paymentType == 'DEBIT_CARD'">Cartão/Deb</span>
                                                <span v-if="report.paymentType == 'TICKET'">Boleto</span>
                                                <span v-if="report.paymentType == 'TICKETS'">Boleto/Carnê</span>
                                                <span v-if="report.paymentType == 'DEBIT_CARD_LOCAL'">Cartão/Deb (Maq)</span>
                                                <span v-if="report.paymentType == 'CREDIT_CARD_LOCAL'">Cartão/Cred (Maq)</span>
                                                <span v-if="report.paymentType == 'MONEY'">Dinheiro</span>
                                            </td>
                                            <td>{{report.cellphone}}</td>
                                            <td>{{report.neighborhood}}</td>
                                            <td>{{report.zipCode}}</td>
                                        </tr>
                                        <tr v-show="listaDetailsDefaulters.length == 0">
                                            <td colspan="8">
                                            <center> Nenhuma informação disponível no relatório para o período informado.</center>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> 

                            </tab>
                        </tabs>
                    </template>

                </card> 
             </div>   

        </form>   
   </div> <!--- fecha conteudo -->

  </div>
</template>

<script>
import { Select, Option } from 'element-ui'
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import FileSaver from 'file-saver'
import { Tabs, Tab } from 'vue-slim-tabs'

export default {
  components: {
        [Select.name]: Select,
        [Option.name]: Option,
        flatPicker,
        Tabs, 
        Tab
  },
  data() {
    return {
        totalReceived: 0,
        totalReceivable: 0,
        totalDefaulters: 0,
        totalValueReceived: 0,
        totalValueReceivable: 0,
        totalValueDefaulters: 0,        
        listaDetailsReceived: [],
        listaDetailsReceivable: [],
        listaDetailsDefaulters: [],
        reportFilter:{
            dateBegin: null,
            dateEnd: null
        }
    }
  },
  methods: {
      printReport(){
          window.print()
      },
      exportReport(){
            this.$clubApi.post('/report/receipt/export', this.reportFilter, {
               responseType : 'blob'
            }).then((response) => {
                var blob = new Blob([response.data], {
                    type: 'application/vnd.ms-excel'
                });
                FileSaver.saveAs(blob, 'relatorio-recebimento.xlsx');
            }) .catch((error) => {
                this.$notify({type: 'warning', message: error.response.data.msg})
            }).finally(() =>{
                NProgress.done() 
            })
      },
      openSubscription(id){
          location.href = '/assinatura/'+id
      },
      filterReport(){
        this.$clubApi.post('/report/receipt', this.reportFilter)
        .then((response) => {
            console.log(response.data.object);
            if(response.data.object != null){
                this.totalReceived = response.data.object.detailsReceived.length;
                this.totalReceivable = response.data.object.detailsReceivable.length;
                this.totalDefaulters = response.data.object.detailsDefaulters.length;
                this.listaDetailsReceived = response.data.object.detailsReceived;
                this.listaDetailsReceivable = response.data.object.detailsReceivable;
                this.listaDetailsDefaulters = response.data.object.detailsDefaulters;
                this.totalValueReceived = response.data.object.receivedFmt;
                this.totalValueReceivable = response.data.object.receivableFmt;
                this.totalValueDefaulters = response.data.object.defaultersFmt;
            }
        }).catch((error) => {
            console.log(error);
           this.$notify({type: 'warning', message: error.response.data.msg})
        }).finally(() =>{
            NProgress.done() 
        })
      }
  }
};
</script>

<style>
.vue-tablist {
  list-style: none;
  display: flex;
  padding-left: 0;
  border-bottom: 1px solid #e2e2e2;
}

.vue-tab {
  padding: 5px 10px;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  border-bottom-color: #e2e2e2;
  border-radius: 3px 3px 0 0;
  background-color: white;
  position: relative;
  bottom: -1px;
  font-size: 13px;
}

.vue-tab[aria-selected="true"] {
  border-color: #e2e2e2;
  border-bottom-color: transparent;
  background-color: #f8f8ff;
}

.vue-tab[aria-disabled="true"] {
  cursor: not-allowed;
  color: #999;
}
</style>